.admin-module {
    background: #f2f2f2;

    .content-header {
        position: relative;
        padding: 15px 15px 0 15px;
    }

    .content-header>h1 {
        margin: 0;
        font-size: 24px;
        display: inline-block;
    }

    .box {
        position: relative;
        border-radius: 3px;
        background: #fff;
        border-top: 3px solid #d2d8de;
        margin-bottom: 20px;
        width: 100%;
        box-shadow: 0 1px 1px rgba(0,0,0,0.1);
    }

    .box-body {
        border-top-left-radius: 0;
        border-top-right-radius: 0;
        border-bottom-right-radius: 3px;
        border-bottom-left-radius: 3px;
        padding: 10px;
        position: relative;
    }

    .content {
        padding: 15px;
    }

    .form__search-box input[type=text]:not(.mat-date-range-input-inner) {
        float: left;
        width: 250px;
    }

    .form__search-box__common > input[type=text] {
        border-radius: 4px 0 0 4px;
    }

    .form__search-box__common button {
        border-radius: 0 4px 4px 0;
        border-left: none;
        float: left;
        margin-top: 0;
        padding-top: 6px;
        padding-bottom: 6px;
    }

    .c-groups__top_buttons {
        padding-top: 15px;
        padding-bottom: 10px;
    }

    .table__button-groups {
        text-align: right;
    }

    tr.disabled,
    .table > tbody > tr.disabled:hover {
        background: #f9f9f9;
    }

    td wo-checkbox,
    th wo-checkbox {
        margin-bottom: -4px;
    }

    .nav {
        padding-left: 0;
        margin-bottom: 0;
        list-style: none;
    }

    .nav-tabs {
        border-bottom: 1px solid #ddd;
        height: 46px;
        width: 100%;
    }

    .nav>li {
        position: relative;
        display: block;
    }

    .nav-tabs>li {
        float: left;
        transform: translateY(3px);
    }

    .nav > li > a {
        position: relative;
        display: block;
        padding: 10px 30px;
    }

    .nav-tabs-custom {
        margin-bottom: 20px;
        background: #fff;
        box-shadow: 0 1px 1px rgba(0,0,0,.1);
        border-radius: 3px;
    }

    .nav-tabs>li>a,
    .nav-tabs>li>a:visited {
        margin-right: 2px;
        line-height: 1.42857143;
        border: 1px solid transparent;
        border-radius: 4px 4px 0 0;
        padding-top: 11px;
        color: #555;
        text-decoration: none;
    }

    .nav-tabs>li.active>a,
    .nav-tabs>li.active>a:focus,
    .nav-tabs>li.active>a:hover {
        color: #555;
        cursor: default;
        background-color: #fff;
        border: 1px solid #ddd;
        border-bottom-color: transparent;
        text-decoration: none;
        transform: translateY(-3px);
        padding-top: 12px;
        border-top: 3px solid black;
        border-radius: 0;
    }

    .nav-tabs>li:first-child.active>a,
    .nav-tabs>li:first-child.active>a:focus,
    .nav-tabs>li:first-child.active>a:hover {
        border-left: none;
    }

    .form__search-box__advanced-btn .fa {
        position: absolute;
        left: 10px;
        top: 11px;
    }

    .form__search-box__advanced-btn .fa-chevron-down {
        display: none;
    }

    .advanced-filter .form__search-box__advanced-btn {
        border-color: #ccc;
        padding-bottom: 10px;
        text-decoration: none;
        border-bottom-color: #fff;
    }

    .advanced-filter .form__search-box__advanced-btn .fa-chevron-down {
        display: inline-block;
    }

    .advanced-filter .form__search-box__advanced-btn .fa-chevron-right {
        display: none;
    }

    .form__advanced-search-box {
        display: none;
    }

    .advanced-filter .form__advanced-search-box {
        display: block;
        padding: 10px;
        border: 1px solid #ccc;
        margin-top: -1px;
    }

    .advanced-filter .form__advanced-search-box_borderless {
        padding: 0;
        border: none;
    }

    .form__advanced-search-box__controls button {
        margin-right: 5px;
    }

    .form__advanced-search-box__body {
        padding-bottom: 15px;
    }

    .form__advanced-search-box__item {
        float: left;
        padding-right: 10px;
    }

    .form__advanced-search-box__item input[type=text],
    .form__advanced-search-box__item select {
        width: 150px;
    }

    .form__advanced-search-box__label {
        font-size: 14px;
        padding-bottom: 2px;
    }

    .box-loading {
        opacity: .6;
        position: relative;
    }

    .box-loading:before {
        content: '';
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        cursor: progress;
        background-repeat: no-repeat;
        background-position: 100% 0;
    }

    .td-link {
        cursor: pointer;
    }

    .td-link:active {
        outline: none;
    }

    .btn-xs {
        padding: 0.3125rem 0.4375rem;
        font-size: 0.75rem;
        line-height: 1.1;
        border-radius: 0.2rem;
    }

    .form__search-box__advanced-btn {
        border: none;
        padding-left: 30px;
        position: relative;
        text-decoration: none;
        padding-top: 6px;
        margin-left: 10px;
        top: 1px;
        color: #3f51b5;
    }

    .form__search-box__advanced-btn a:active {
        color: blue;
    }

    .c-page {
        padding: 30px;
    }

    .box-body > .c-page {
        padding: 20px;
    }

    .mat-tooltip {
      font-size: 14px !important;
    }

    textarea {
        line-height: 1.4em;
        transform: translateY(-2px);
    }
}

* {
    scrollbar-color: #aaaaaa66 #eeeeeeaa ;
    scrollbar-width: 6px;
}

::-webkit-scrollbar {
    width: 12px;
    height: 12px;
}
::-webkit-scrollbar-track {
    background: #eeeeeeaa;
    // border: thin solid lightgray;
    // box-shadow: 0px 0px 3px #dfdfdf inset;
    // border-radius: 10px;
}
::-webkit-scrollbar-thumb {
    background: #aaaaaa66;
    // border: thin solid gray;
    border-radius: 10px;
    border: 3px transparent solid;
    background-clip: padding-box;
}
::-webkit-scrollbar-thumb:hover {
    background: #999999aa;
}
