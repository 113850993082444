/* You can add global styles to this file, and also import other style files */

*,
*::before,
*::after {
  box-sizing: border-box;
}

// Primeng 
// @import "~primeng/resources/themes/saga-blue/theme.css";
// @import "~primeng/resources/primeng.min.css";
// @import "~primeicons/primeicons.css";

/* For All Modules */
@import "~./assets/styles/common";

/* For Admin Module */
@import "~./assets/styles/admin";

/* For Public Module */
@import "~./assets/styles/public";

/* For General Module */
@import "~./assets/styles/general";

/* For Scheduling Module */
@import "~./assets/styles/scheduling";


/* Bootstrap Grid */
@import "~./assets/styles/bootstrap-grid.css";


/*Quill*/
@import '~quill/dist/quill.core.css';
@import '~quill/dist/quill.bubble.css';
@import '~quill/dist/quill.snow.css'; 

html,
body {
  height: 100%;
}

body {
  margin: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

@import '@angular/material/theming';
@include mat-core();


$app-primary: mat-palette($mat-green, 400, 300, 700);
$app-accent: mat-palette($mat-green, 100, 500, 300);

$primary: #94cf96;
$accent: #4caf50;

$app-theme: mat-light-theme($app-primary, $app-accent);

@mixin mix-app-theme($app-theme) {

//   .mat-button-toggle:not(.mat-button-toggle-checked) {
//     background-color: $primary;
//     color: $primary;
//   }

  .mat-button-toggle-checked {
    background-color: #3f51b5;
    color: $accent;
  }

  .mat-button-toggle-checked .mat-button-toggle-label-content {
    color: #fff;
  }

  .mat-button-toggle-ripple > div {
    background-color: #3f51b5;
    opacity: 0.1;
  }

  .mat-slide-toggle.mat-checked:not(.mat-disabled) .mat-slide-toggle-bar {
    background-color: $primary;
  }

  .mat-slide-toggle.mat-checked:not(.mat-disabled) .mat-slide-toggle-thumb {
    background-color: $accent;
  }

  .mat-slide-toggle.mat-checked:not(.mat-disabled) .mat-ripple-element {
    background-color: $primary;
  }

  .mat-checkbox-checked:not(.mat-checkbox-disabled):not(.mat-checkbox-primary).mat-accent .mat-ripple-element, .mat-checkbox:active:not(.mat-checkbox-disabled).mat-accent .mat-ripple-element  {
    background-color: $accent;
  }

  .mat-checkbox-checked:not(.mat-checkbox-disabled):not(.mat-checkbox-primary) .mat-checkbox-background {
    background-color: $accent;
  }

  .mat-slide-toggle.mat-checked.mat-disabled .mat-slide-toggle-bar {
    background-color: $primary;
  }

  .mat-slide-toggle.mat-checked.mat-disabled .mat-slide-toggle-thumb {
    background-color: $accent;
  }

  .mat-slide-toggle.mat-checked.mat-disabled .mat-ripple-element {
    background-color: $primary;
  }

  // .mat-checkbox-checked.mat-checkbox-disabled.mat-accent .mat-ripple-element, .mat-checkbox:active.mat-checkbox-disabled.mat-accent .mat-ripple-element  {
  //   background-color: $accent;
  // }

  // .mat-checkbox-checked.mat-checkbox-disabled .mat-checkbox-background {
  //   background-color: $accent;
  // }
}

// Include the mixin
@include mix-app-theme($app-theme);

html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }


.is-dev-only {
    display: none !important;
}

.mat-header-cell {
  background-color: #fafafa;
  font-weight: bold;
  font-size: 16px;
  color: #000;
}

@font-face {
  font-family: "RoundGothic";
  src: local("RoundGothic") url('/assets/fonts/all-round-gothic.ttf') format("truetype");
}